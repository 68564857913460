import React from "react";
import { graphql } from "gatsby";
import pathBuilder from "../../utils/pathBuilder";
import Layout from "../../components/Layout/Layout";
import PageIntro from "../../components/PageIntro/PageIntro";
import QuizComponent from "../../components/QuizComponent";
import SocialShare from "../../components/SocialShare";

const QuizTemplate = (props) => {
  const {
    contentful_id,
    node_locale,
    title,
    __typename,
    description,
    seoTitle,
    seoDescription,
    slug,
  } = props.data.quiz;

  const environment = {
    lang: node_locale,
    id: contentful_id,
    path: pathBuilder(node_locale, __typename, slug),
    theme: "orange",
    altHrefLang: [
      {
        lang: node_locale,
        path: pathBuilder(node_locale, __typename, slug),
      },
      {
        lang: props.data.alt_quiz.node_locale,
        path: pathBuilder(
          props.data.alt_quiz.node_locale,
          props.data.alt_quiz.__typename,
          props.data.alt_quiz.slug,
        ),
      },
    ],
    seo: {},
  };
  if (seoDescription) {
    environment.seo.description = seoDescription;
  }
  if (seoTitle) {
    environment.seo.title = seoTitle;
  } else {
    environment.seo.title = title;
  }
  return (
    <Layout environment={environment}>
      <PageIntro>
        <h1 style={{ fontSize: "1.3rem", margin: "1rem 0" }}>{title}</h1>
        <div
          style={{ margin: "0" }}
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        />
        <SocialShare cta={"Share & Save"} environment={environment} />
      </PageIntro>
      <QuizComponent quiz={props.data.quiz} />
    </Layout>
  );
};

export const quizQuery = graphql`
  query quizQuery($lang: String!, $id: String!, $altLang: String) {
    quiz: contentfulQuiz(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      ...QuizFragment
    }
    alt_quiz: contentfulQuiz(
      contentful_id: { eq: $id }
      node_locale: { eq: $altLang }
    ) {
      title
      slug
      node_locale
      __typename
    }
    defaultFbImage: file(relativePath: { eq: "TDOA-SOCIAL-DEFAULT.png" }) {
      publicURL
    }
  }
`;

export default QuizTemplate;
